// List group action items behave more like buttons,
// so disable text-decoration.s
.list-group-item-action {
	text-decoration: none;
}

// Contextual variants
//
// Use contrast function to determine alert text color

@each $color, $value in $theme-colors {
	@include list-group-item-variant($color, theme-color-level($color, 0), color-yiq(theme-color($color)));
}